.component-navigator {
	.btn {
		font-size: 0.8rem;
	}
}

.full-height {
	height: 100vh;
	max-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.clipped-box {
	overflow: hidden;
	display: flex;
	align-items: center;
}

.dropdown-item.active .btn.btn-link {
	color: $light;
}

.table.td-sm td {
	padding: 0.5rem 0.2rem;
}

.shadow-right {
	box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.2);
}
.shadow-left {
	box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.2);
}

.main-container {
	min-height: calc(100vh - 120px);
}

.action-container {
	margin-top: 15vh;
}

.navbar-dark .navbar-brand {
	color: rgba(255, 255, 255, 0.5);
}

.img-clip {
	display: inline-block;
	overflow: hidden;

	img {
		height: auto;
		width: 100%;
	}
}

.input-group-text {
	> * {
		margin: 0 !important;
	}
}

.rmd p:only-child {
	margin: 0;
}

label p:only-child {
	margin: 0;
}

// .navbar-dark .vertical .navbar-nav {
// 	> .nav-link {
// 		border-bottom-color: $navbar-dark-color;
// 	}
// }
.vertical .navbar-nav {
	width: 100%;
	flex-direction: column;
	// > .nav-link {
	// 	border-bottom: 1px solid $gray-400;
	// 	margin: 0;
	// }
}

.no-chevron::after {
	display: none !important;
}

.bg-none {
	background: none;
}

.position-top-left {
	position: absolute;
	top: 0;
	left: 0;
}
.position-top-right {
	position: absolute;
	top: 0;
	right: 0;
}
.position-bottom-left {
	position: absolute;
	bottom: 0;
	left: 0;
}
.position-bottom-right {
	position: absolute;
	bottom: 0;
	right: 0;
}

.tox-notification.tox-notification--in.tox-notification--warning {
	display: none;
}

.dropdown-item.row {
	display: flex;
}

@for $i from 1 through 10 {
	.hover-group-#{$i} {
		.hover-content-#{$i} {
			opacity: 0;
		}
		.hover-content-show-#{$i} {
			opacity: 1;
		}
		.hover-switch-hover-#{$i} {
			display: none;
		}
	}

	.hover-group-#{$i}:hover {
		.hover-content-#{$i} {
			opacity: 1;
		}
		.hover-content-show-#{$i} {
			opacity: 0;
		}
		.hover-switch-init-#{$i} {
			display: none;
		}
		.hover-switch-hover-#{$i} {
			display: block;
		}
	}
}

.global-nav {
	top: 0;
}

.global-notification {
	position: fixed;
	z-index: 20;
}

.global-notification ~ .global-nav {
	top: 50px;
}

@media print {
	.dp-none {
		display: none !important;
	}
}

// .ag-cell-value.ag-cell-focus {
// 	overflow: inherit;
// }

.ck.ck-content {
	&.ck-editor__editable_inline {
		border: $card-border-width solid $card-border-color;
	}
}

.ck-editor__editable_inline {
	min-height: 40vh;
}

.navbar-expand .navbar-collapse {
	max-width: 100%;
}

#root {
	.MuiSnackbar-root {
		z-index: 5000;
	}
	.MuiAlert-root {
		margin-bottom: 0.5rem;
	}
}

.form-group {
	margin-bottom: 1rem;
}
