.card-header,
.card-body {
	> *:only-child {
		margin: 0 !important;
	}
}

.card.bg-secondary {
	border: none;
	// #DDDDDD
}

.accordion > .card {
	overflow: visible;
}

@each $color, $value in $theme-colors {
	.media-quote-#{$color} {
		border-left: 2px solid rgba($value, 0.9);
		margin-left: 2rem;
	}
}
