// @import 'sass/darkly';
@import '@outsource-school/helper/dist/sass/os-theme';
$min-contrast-ratio: 3 !default;

// Import Bootstrap and its default variables
// @import '~@fortawesome/fontawesome-free/scss/fontawesome';
// @import '~@fortawesome/fontawesome-free/scss/brands';
// @import '~@fortawesome/fontawesome-free/scss/regular';
// @import '~@fortawesome/fontawesome-free/scss/solid';

@import '~bootstrap/scss/bootstrap';
@import '@outsource-school/helper/dist/sass/os-theme-ext';
// @import '@outsource-school/helper/src/sass/_swatch';
@import 'sass/all';
