@each $color, $value in $theme-colors {
	.border-hover-#{$color}:hover {
		border-color: $value !important;
	}
}

.border-hover-focus {
	&:focus,
	&.focus,
	&:hover,
	&.hover {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}
}
