.horizontal-drawer {
	max-width: 100vw;
}

.side-nav {
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	z-index: 20;

	@media (min-width: 768px) {
		padding-right: 1.4%;
	}
	@media (min-width: 992px) {
		padding-right: 7px;
	}
}

$nav-uncondensed-width: 200px;
$nav-condensed-width: 45px;
.nav-uncondensed {
	top: 0;
	z-index: 1100;
	width: $nav-uncondensed-width;
	& ~ .global-nav {
		left: #{$nav-uncondensed-width - 1px};
		width: calc(100vw - #{$nav-uncondensed-width - 1px});
	}

	& ~ .main-content {
		margin-left: #{$nav-uncondensed-width - 1px};
		width: calc(100vw - #{$nav-uncondensed-width - 1px});
	}
}

.nav-condensed {
	top: 0;
	z-index: 1100;
	width: $nav-condensed-width;
	& ~ .global-nav {
		left: #{$nav-condensed-width - 1px};
		width: calc(100vw - #{$nav-condensed-width - 1px});
	}

	& ~ .main-content {
		margin-left: #{$nav-condensed-width - 1px};
		width: calc(100vw - #{$nav-condensed-width - 1px});
	}
}
