.input-group-rounded {
	border: $input-border-width solid $input-border-color;

	.input-group-prepend {
		border: none;
		background: $light;
	}
	.input-group-append {
		border: none;
		background: $light;
	}
	.form-control {
		border: none;
	}
}

.signup-radio {
	.input-group-prepend {
		position: absolute;
		left: -4rem;
	}
	.input-group-append {
		position: absolute;
		right: -8rem;
	}
}
