.btn.rounded-circle {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	padding: 0;
	padding-left: 1px;

	height: 2.5rem;
	width: 2.5rem;

	&.btn-sm {
		height: 2rem;
		width: 2rem;
	}

	&.btn-lg {
		height: 5rem;
		width: 5rem;
	}
}
