.ag-cell.ag-cell-focus {
	overflow: visible;
}

.ag-row {
	z-index: 0;
}

.ag-row.ag-row-focus {
	z-index: 1;
}
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height {
	overflow: visible;
}