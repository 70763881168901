.swiper-navigation {
	display: flex;
	position: absolute;
	right: 1rem;
	top: -3rem;
	z-index: 10;
}

.swiper-custom-navigation {
	position: relative;
}
