hr.dashed {
	border-top: 2px dashed $secondary;
}

hr.dotted {
	border-top: 2px dotted $secondary;
}

hr.solid {
	border-top: 2px solid $secondary;
}

hr.hr-text {
	position: relative;
	border: none;
	height: 1px;
	background: $secondary;
}

hr.hr-text::before {
	content: attr(data-content);
	display: inline-block;
	background: #fff;
	font-weight: bold;
	font-size: 0.85rem;
	color: $secondary;
	border-radius: 30rem;
	padding: 0.2rem 2rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
